.gpt3__blog-container_article {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: var(--color-footer);
    cursor: pointer;
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
}
.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.gpt3__blog-container_article-content {
    color: white;
    font-family: var(--font-family);
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.gpt3__blog-container_article-content p {
    font-size: 12px;
    line-height: 35px;
    font-weight: bold;
}

.gpt3__blog-container_article-content h3 {
    font-size: 25px;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 5rem;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content {
        font-size: 18px;
        line-height: 25px;
    }
}
