@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.gpt3__blog-container_article {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    background: var(--color-footer);
    cursor: pointer;
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
}
.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.gpt3__blog-container_article-content {
    color: white;
    font-family: var(--font-family);
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.gpt3__blog-container_article-content p {
    font-size: 12px;
    line-height: 35px;
    font-weight: bold;
}

.gpt3__blog-container_article-content h3 {
    font-size: 25px;
    line-height: 30px;
    font-weight: 800;
    margin-bottom: 5rem;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content {
        font-size: 18px;
        line-height: 25px;
    }
}

.gpt3__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    margin: 3rem 6rem;
    border-radius: 1rem;
    background: var(--gradient-bar);
}

.gpt3__cta-content {
    font-family: var(--font-family);
}

.gpt3__cta-content p {
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #0E0E0E;
}

.gpt3__cta-content h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 34px;
    color: #000;
}

.gpt3__cta-btn button{
    font-family: var(--font-family);
    font-weight: bold;
    border-radius: 40px;
    background: #000;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}


@media screen and (max-width: 700px) {
    .gpt3__cta {
        flex-direction: column;
        margin: 3rem 4rem;
    }
    .gpt3__cta-btn {
        margin: 2rem 0 0 ;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__cta {
        margin: 4rem 2rem;
    }
    .gpt3__cta-content h3 {
        font-size: 18px;
        line-height: 28px;
    }

    .gpt3__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}

.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.gpt3__features-container__feature-title h1{
    color: white;
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
}

.gpt3__features-container__feature-title div{
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    margin-bottom: 0.25rem;
}

.gpt3__feature-container__feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.gpt3__feature-container__feature-text p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }
    .gpt3__features-container__feature-text p {
        font-size: 12px;
        line-height: 20px;
    }
    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
}

.gpt3__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gpt3__brand div {
    flex: 1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.gpt3__navbar-links_logo {
    margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
    width: 63px;
    height: 16px;
}


.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: white;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    background: #FF4820;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.gpt3__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}


@media screen and (max-width: 500px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }

}

.gpt3__blog {
    display: flex;
    flex-direction: column;
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}
.gpt3__blog-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__blog-container {
    display: flex;
    justify-content: space-between;
}

.gpt3__blog-container__groupA {
    flex: 0.75 1;
    margin-right: 2rem;
}

.gpt3__blog-container__groupB {
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}


@media screen and (max-width: 990px) {
    .gpt3__blog-container {
        flex-direction: column;
    }
    .gpt3__blog-container__groupA {
        margin-right: 0;
        margin-bottom: 1rem;
    }
    .gpt3__blog-container__groupB {
        grid-gap: 1rem;
    }

    .gpt3__blog-heading h1{
        font-size: 46px;
        line-height: 52px;
    }
}


@media screen and (max-width: 650px) {

    .gpt3__blog-container__groupB {
        display: flex;
        flex-direction: column;
    }

    .gpt3__blog-heading h1{
        font-size: 36px;
        line-height: 46px;
    }
}

.gpt3__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.gpt3__features-heading {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}

.gpt3__features-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
}

.gpt3__features-heading p {
    font-family: var(--font-family);
    color: var(--color-subtext);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    margin-top: 2rem;
}

.gpt3__features-container {
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}


@media screen and (max-width: 990px) {
    .gpt3__features {
        flex-direction: column;
    }
    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }

}
@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }

}

.gpt3__footer {
    width: 100%;
    font-family: var(--font-family);
    background: var(--color-footer);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gpt3__footer-heading {
    margin: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
}

.gpt3__footer-heading h1 {
    font-size: 52px;
    text-align: center;
    font-weight: 800;
}

.gpt3__footer-btn button{
    background: none;
    padding: 1rem 2rem;
    border: 1px solid white;
    color: white;
    border-radius: 2px;
}

.gpt3__footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    color: white;
    padding: 10%;
}

.gpt3__footer-content-image {
    flex: 1 1;
    font-size: 14px;
    width: 100%;
}

.gpt3__footer-content-image img {
    width: 50%;
    max-width: 150px;
    min-width: 100px;
    margin-bottom: 1rem;
}
.gpt3__footer-content-image p {
    max-width: 200px;
}

.gpt3__footer-content__lists {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1 1;
    font-size: 14px;
    width: 100%;
}

.gpt3__footer-content__lists-container {
    display: flex;
    flex-direction: column;
}

.gpt3__footer-content__lists-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 150px;
    margin-top: 1rem;
}

.gpt3__footer-content__lists-container h5 {
    font-weight: 800;
    font-size: 16px;
}

.gpt3__footer-content__lists-content a {
    margin-top: 2rem;
    font-weight: 300;
}

.gpt3__footer-copyright p {
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
}

@media screen and (max-width: 990px) {

    .gpt3__footer-heading h1 {
        font-size: 42px;
    }

    .gpt3__footer-content {
        flex-direction: column;
    }

    .gpt3__footer-content-image {
        margin-bottom: 2rem;
        justify-self: center;
    }
}

@media screen and (max-width: 650px) {

    .gpt3__footer-heading h1 {
        font-size: 36px;
    }
    .gpt3__footer-content__lists {
        flex-direction: column;
    }

    .gpt3__footer-content__lists-content a {
        margin-top: 0.2rem;
    }

    .gpt3__footer-content__lists-content {
        margin-top: 0.1rem;
        max-width: 100%;
    }

    .gpt3__footer-content__lists-container {
        margin-top: 2rem;
    }

    .gpt3__footer-content {
        justify-content: center;
        align-items: center;

    }
}

.gpt3__header {
    display: flex;
}

.gpt3__header-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 0rem;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;

    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}


.gpt3__header-content p {
    font-family: var(--font-family);
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.gpt3__header-content__input input {
    flex: 2 1;
    width: 100%;
    min-height: 50px;
    background: var(--color-footer);
    border-radius: 5px 0 0 5px;
    font-family: var(--font-family);
    border: 2px solid var(--color-footer);
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
    padding: 0 1rem;
}

.gpt3__header-content__input button {
    flex: 0.6 1;
    width: 100%;
    min-height: 50px;
    background: #FF4820;
    border-radius: 0 5px 5px 0;
    font-family: var(--font-family);
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: white;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0 1rem;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 182px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: white;
    text-align: center;
}

.gpt3__header-image {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;

}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}


@media screen and (max-width: 700px) {
    .gpt3__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header p {
        font-size: 16px;
        line-height: 24px;
    }

    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input button,
    .gpt3__header-content__input input {
        font-size: 16px;
        line-height: 24px;
    }
}


@media screen and (max-width: 490px) {
    .gpt3__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input button,
    .gpt3__header-content__input input {
        font-size: 14px;
        line-height: 24px;
    }
}


.gpt3__possibility {
    display: flex;
    flex-direction: row;
}

.gpt3__possibility-image{
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;

}

.gpt3__possibility-image img{
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}
.gpt3__possibility-content h1{
    font-family: var(--font-family);
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    margin: 1rem 0;
}
.gpt3__possibility-content h4{
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    color: #71E5FF;
    cursor: pointer;
}
.gpt3__possibility-content h4:last-child {
    color: var(--color-subtext);
}

.gpt3__possibility-content p{
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    color: var(--color-text);
    margin-bottom: 2rem;
}


@media screen and (max-width: 990px) {
    .gpt3__possibility {
        flex-direction: column;
    }
    .gpt3__possibility-content {
        margin-top: 2rem;
    }
    .gpt3__possibility-image {
        margin: 1rem 0;
    }
}

@media screen and (max-width: 550px) {

    .gpt3__possibility-content h1 {
        font-size: 28px;
        line-height: 38px;
    }
    .gpt3__possibility-content h4,
    .gpt3__possibility-content p {
        font-size: 14px;
        line-height: 24px;
    }
}

.gpt3__whatgpt3 {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3__whatgpt3-feature {
    display: flex;
}

.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
}
.gpt3__whatgpt3-feature .gpt3__features-container__feature-text {
    max-width: 700px;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-family: var(--font-family);
    font-weight: 800;
    line-height: 45px;
    font-size: 34px;
    max-width: 510px;
}


.gpt3__whatgpt3-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    line-height: 30px;
    font-size: 16px;
    max-width: 510px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 2rem;
}


/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg);
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 100%);

}

.gradient__text {
    background: var(--gradient-text);
    --webkit-background-clip: text;
    -webkit-background-clip: text;
            background-clip: text;
    color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}
.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center {
    animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@keyframes scale-up-center {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}


@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }
    .section__margin {
        margin: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }
    .section__margin {
        margin: 4rem 2rem;
    }
}

:root {
    --font-family: 'Manrope', sans-serif;

    --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
    --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);

    --color-bg: #040C18;
    --color-footer : #031B34;
    --color-blog: #042c54;
    --color-text: #81AFDD;
    --color-subtext: #FF8A71;
}

