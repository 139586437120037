.gpt3__blog {
    display: flex;
    flex-direction: column;
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;
    margin-bottom: 5rem;
}
.gpt3__blog-heading h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;
}

.gpt3__blog-container {
    display: flex;
    justify-content: space-between;
}

.gpt3__blog-container__groupA {
    flex: 0.75;
    margin-right: 2rem;
}

.gpt3__blog-container__groupB {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}


@media screen and (max-width: 990px) {
    .gpt3__blog-container {
        flex-direction: column;
    }
    .gpt3__blog-container__groupA {
        margin-right: 0;
        margin-bottom: 1rem;
    }
    .gpt3__blog-container__groupB {
        grid-gap: 1rem;
    }

    .gpt3__blog-heading h1{
        font-size: 46px;
        line-height: 52px;
    }
}


@media screen and (max-width: 650px) {

    .gpt3__blog-container__groupB {
        display: flex;
        flex-direction: column;
    }

    .gpt3__blog-heading h1{
        font-size: 36px;
        line-height: 46px;
    }
}
