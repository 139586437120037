.gpt3__footer {
    width: 100%;
    font-family: var(--font-family);
    background: var(--color-footer);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gpt3__footer-heading {
    margin: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 800px;
}

.gpt3__footer-heading h1 {
    font-size: 52px;
    text-align: center;
    font-weight: 800;
}

.gpt3__footer-btn button{
    background: none;
    padding: 1rem 2rem;
    border: 1px solid white;
    color: white;
    border-radius: 2px;
}

.gpt3__footer-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    color: white;
    padding: 10%;
}

.gpt3__footer-content-image {
    flex: 1;
    font-size: 14px;
    width: 100%;
}

.gpt3__footer-content-image img {
    width: 50%;
    max-width: 150px;
    min-width: 100px;
    margin-bottom: 1rem;
}
.gpt3__footer-content-image p {
    max-width: 200px;
}

.gpt3__footer-content__lists {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
    font-size: 14px;
    width: 100%;
}

.gpt3__footer-content__lists-container {
    display: flex;
    flex-direction: column;
}

.gpt3__footer-content__lists-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 150px;
    margin-top: 1rem;
}

.gpt3__footer-content__lists-container h5 {
    font-weight: 800;
    font-size: 16px;
}

.gpt3__footer-content__lists-content a {
    margin-top: 2rem;
    font-weight: 300;
}

.gpt3__footer-copyright p {
    color: white;
    font-family: var(--font-family);
    font-size: 14px;
}

@media screen and (max-width: 990px) {

    .gpt3__footer-heading h1 {
        font-size: 42px;
    }

    .gpt3__footer-content {
        flex-direction: column;
    }

    .gpt3__footer-content-image {
        margin-bottom: 2rem;
        justify-self: center;
    }
}

@media screen and (max-width: 650px) {

    .gpt3__footer-heading h1 {
        font-size: 36px;
    }
    .gpt3__footer-content__lists {
        flex-direction: column;
    }

    .gpt3__footer-content__lists-content a {
        margin-top: 0.2rem;
    }

    .gpt3__footer-content__lists-content {
        margin-top: 0.1rem;
        max-width: 100%;
    }

    .gpt3__footer-content__lists-container {
        margin-top: 2rem;
    }

    .gpt3__footer-content {
        justify-content: center;
        align-items: center;

    }
}
